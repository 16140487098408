import React, { useEffect, useState } from 'react';
import { useWings } from '@wingscms/react';
import { navigate } from 'gatsby';
import { ComplexCard, FlexGrid, wide } from '@wingscms/crane';
import { routing } from '@wingscms/hummingbird';
import styled from 'styled-components';

const Container = styled.div`
  ${wide};
  margin-top: 80px;
  margin-bottom: 80px;
  @media screen and (max-width: 800px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const HighlightedArticles = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;
  height: auto;
  padding: 10px;
`;

export default ({ items, divisions }) => {
  const wings = useWings();
  const [loop, setLoop] = useState([]);
  useEffect(() => {
    wings
      .query(
        `query {
        ${items.map(
          (x, i) => `
          item${i}: entry(selector: { id: { eq: "${x.id}" } }) {
            id
            title
            nodeType
            resourceType
            locale {
              id
              name
              primary
            }
            featured {
              title
              description
              image {
                url
              }
            }
            slug
            image {
              name
              id
              url
              alt
              caption
            }
          }
        `
        )}}`
      )
      .then(res => {
        const data = [];
        items.map((x, i) => {
          data.push(res[`item${i}`]);
        });
        setLoop(data);
      });
  }, []);
  return (
    <Container>
      <HighlightedArticles>
        <FlexGrid
          divisions={divisions || 3}
          margins={10}
          alignItems="stretch"
          style={{
            marginLeft: '-10px',
            marginTop: '10px',
            marginBottom: '10px',
            width: 'calc(100% + 20px)'
          }}
        >
          {loop &&
            loop.map(node => (
              <ComplexCard
                item={node}
                key={`node${node.id}`}
                title={node.featured && node.featured.title}
                image={
                  node.featured &&
                  node.featured.image &&
                  node.featured.image.url
                }
                onClickHandler={() => {
                  navigate(routing.getPath(node));
                }}
                summary={node.featured && node.featured.description}
                size="medium"
                bottomBackground
                shadow
              />
            ))}
        </FlexGrid>
      </HighlightedArticles>
    </Container>
  );
};
