import { MenuItem } from '@wingscms/crane';
import styled from 'styled-components';

export default styled(MenuItem)`
  display: block;
  line-height: auto;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  margin: 20px auto;
  padding: 0;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    animation: burger-glitch-after;
    animation-duration: 0.2s;
    @keyframes burger-glitch-after {
      0% {
        color: pink;
        transform: translateX(-3%) translateY(2%);
      }
      5% {
        color: cyan;
        transform: translateX(2%) translateY(6%);
      }
      15% {
        color: pink;

        transform: translateX(-8%) translateY(-30%);
      }
      20% {
        color: pink;
        transform: translateX(0%) translateY(17%);
      }
      25% {
        color: #000;
        transform: translateX(-4%) translateY(0);
      }
      30% {
        color: cyan;
        transform: translateX(-3%) translateY(10%);
      }
      35% {
        color: pink;
        transform: translateX(7%) translateY(5%);
      }
      40% {
        color: #000;
        transform: none;
      }
      95% {
      }
      100% {
        transform: none;
      }
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    background-image: linear-gradient(120deg, #000 0%, #000) !important;
    font-family: 'Edmondsans';
    background-repeat: no-repeat;
    background-size: 100% 0px;
    background-position: 0% 100%;
    transition: background-size 0.1s linear;
    &:hover,
    &:focus {
      background-size: 100% 4px;
    }
    img {
      margin-left: 10px;
    }
  }
  &.special {
    a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  &.current {
    a {
      background-size: 100% 4px;
    }
  }
  &:hover,
  &:active {
    ul {
      display: block;
    }
  }
`;
