/* eslint-disable */
import React from 'react';
import { createSvgIcon } from '@wingscms/crane';

export default createSvgIcon(
  <React.Fragment>
    <polygon
      points="204,471.75 204,318.75 306,318.75 306,471.75 433.5,471.75 433.5,267.75 510,267.75 255,38.25 0,267.75
			76.5,267.75 76.5,471.75 		"
    />
  </React.Fragment>,
  'facebook',
  '0 0 510 510',
);
