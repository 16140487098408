import React from 'react';
import styled from 'styled-components';
import { Icons } from '@wingscms/crane';

const Icon = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  opacity: 1;
  position: relative;
  transition: all 0.1s linear;
  &:hover {
    opacity: 0.8;
  }
  > a > svg {
    width: 100%;
    height: 100%;
    path,
    polygon,
    circle,
    rect {
      fill: ${({ iconColor }) => iconColor};
    }
  }
`;

export default (name, url, iconColor, backgroundColor) => {
  switch (name) {
    case 'facebook':
      return (
        <Icon
          iconColor={iconColor || '#3b5998'}
          backgroundColor={backgroundColor}
        >
          <a href={url}>
            <Icons.Facebook />
          </a>
        </Icon>
      );

    case 'twitter':
      return (
        <Icon
          iconColor={iconColor || '#1da1f2'}
          backgroundColor={backgroundColor}
        >
          <a href={url}>
            <Icons.Twitter />
          </a>
        </Icon>
      );

    default:
      return <div />;
  }
};
