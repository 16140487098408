import React from 'react';
import { createCard } from '@wingscms/react';
import { DataCard } from '@bolster/hummingbird-utils';
import HighlightedContent from './HightlightedContent';
import Audio from './Audio';

export default createCard({
  name: 'DataCard',
  renderWith: DataCard([
    {
      name: 'highlightedContent',
      comp: props => <HighlightedContent {...props} />
    },
    {
      name: 'audio',
      comp: props => <Audio {...props.options} />
    }
  ])
});
