import React, { Fragment } from 'react';
import styled from 'styled-components';
import Section from './Section';
// import Button from '../../../../../src/components/Buttons';
import socialMediaIcon from './SocialMediaIcon';

const AddressLine = styled.p`
  margin: 0;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 20px;
  > div {
    width: 80px;
    height: 80px;
    padding: 10px;
  }
`;

export default data =>
  data
    ? data.map(block => (
      <Section>
        {block.map(x => {
          switch (x[0]) {
            case 'title':
              return <p className="title">{x[1]}</p>;
            case 'text':
              return (
                <AddressLine>
                  {x[1].map(y => (
                    <Fragment>
                      {y}
                      <br />
                    </Fragment>
                  ))}
                </AddressLine>
              );
            case 'phone':
              return (
                <AddressLine>
                  <a className="footerLink" href={`tel:${x[1]}`}>
                    {x[1]}
                  </a>
                </AddressLine>
              );
            case 'email':
              return (
                <AddressLine>
                  <a className="footerLink" href={`mailto:${x[2]}`}>
                    {x[1]}
                  </a>
                </AddressLine>
              );
            case 'link':
              return (
                <AddressLine>
                  <a className="footerLink" href={`${x[2]}`}>
                    {x[1]}
                  </a>
                </AddressLine>
              );
            case 'button':
              return <Button href={x[2]} buttonText={x[1]} size="small" />;
            case 'social':
              return (
                <SocialMediaWrapper>
                  {x[1].map(y => socialMediaIcon(y[0], y[1], y[2], y[3]))}
                </SocialMediaWrapper>
              );
            default:
              return null;
          }
        })}
      </Section>
    ))
    : null;
