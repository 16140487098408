import React, { Component } from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import Cross from './Cross';
import MenuItem from './MenuItem';
import GhostContainer from './GhostContainer';
import TicketButton from '../../../../components/TicketButton';
import ShareButtons from '../ShareButtons';

import Home from '../../../../img/home';

const Container = styled.div`
  height: 100px;
  background: #000;
  color: #fff;
  width: 100vw;
  position: absolute;
  top: 0;
  &.sticky {
    position: fixed;
    top: 0;
  }
  @media screen and (max-width: 900px) {
    height: 50px;
  }
  z-index: 99999;
`;

const Logo = styled(Home)`
  display: inline-block;
  width: auto;
  height: 100%;
  padding: 30px;
  position: relative;
  float: left;
  fill: #fff;
  &:hover {
    fill: #ffed00;
  }
  @media screen and (max-width: 900px) {
    padding: 10px;
  }
`;

const MenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  color: #000;
  background-color: #ffed00;
  display: none;
  z-index: 99999;
  top: 0;
  &.show {
    display: block;
  }
`;

const ListContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 100px);
  overflow: auto;
  @media screen and (max-width: 900px) {
    height: calc(100vh - 50px);
  }
`;

const shareUrls = {
  facebook:
    'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fceta-campaign--wijstoppenceta.netlify.com',
  twitter: 'asdasds',
  email: 'asdasd',
  whatsapp: 'asdasdasd'
};

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      showMenu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  componentDidMount() {
    const { offsetTop } = this.container.offsetParent;
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > offsetTop) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }
    });
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    const { showMenu, sticky } = this.state;
    const { items, shareUrls } = this.props;
    return (
      <Container
        className={sticky ? 'sticky' : ''}
        ref={container => {
          this.container = container;
        }}
      >
        <MenuContainer className={showMenu ? 'show' : ''}>
          <Container>
            <a href="/">
              <Logo />
            </a>
            <Cross onClick={() => this.toggleMenu()} />
            <TicketButton />
            <ShareButtons {...shareUrls} />
          </Container>
          <GhostContainer />
          <ListContainer>
            {items &&
              items.map(x => {
                let isIE = null;
                if (typeof document !== 'undefined') {
                  isIE = /*@cc_on!@*/ false || !!document.documentMode;
                }
                return (
                  <MenuItem
                    to={
                      isIE && x.url === '/tickets/'
                        ? 'https://tickets.voordemensen.nl/angstfabriek/cal'
                        : x.url
                    }
                    text={x.text}
                    onClick={this.toggleMenu}
                  />
                );
              })}
            <ShareButtons {...shareUrls} list />
          </ListContainer>
        </MenuContainer>
        <a href="/">
          <Logo />
        </a>
        <Burger onClick={() => this.toggleMenu()} />
        <TicketButton />
        <ShareButtons {...shareUrls} />
      </Container>
    );
  }
}
