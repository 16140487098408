import React from 'react';
import styled from 'styled-components';
import { Icons } from '@wingscms/crane';

const ShareContainer = styled.div`
  display: ${({ list }) => (list ? 'block' : 'inline-block')};
  text-align: center;
  margin-top: 30px;
  float: ${({ list }) => (list ? 'none' : 'right')};
  > p {
    font-size: 15px;
    color: ${({ list }) => (list ? '#000' : '#fff')};
    font-family: 'Edmondsans';
    margin: 0;
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
    margin-right: 10px;
  }
  a:last-child {
    border-right: ${({ list }) => (list ? 'none' : '1px solid #fff')};
    margin-right: ${({ list }) => (list ? '' : '10px')};
  }
  @media screen and (max-width: 900px) {
    margin-top: 5px;
  }
  @media screen and (max-width: 470px) {
    display: ${({ list }) => (list ? 'block' : 'none')};
  }
`;

const ShareImage = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin: 0;
  margin-top: 20px;
  margin-right: 15px;
  transform: translateY(-50%);
  svg {
    fill: ${({ list }) => (list ? '#000' : '#fff')};
  }
  &:hover,
  &:active {
    svg {
      fill: ${({ list, theme }) => (list ? '#fff' : theme.primaryColor)};
    }
  }
`;

export default ({
  color,
  email,
  facebook,
  twitter,
  whatsapp,
  text,
  bottomMargin = true,
  list,
  ...props
}) => (
  <ShareContainer {...props} list={list}>
    <p>{text || 'DEEL:'}</p>
    {facebook && (
      <a href={facebook} target="_blank" rel="noopener noreferrer">
        <ShareImage list={list}>
          <Icons.Facebook />
        </ShareImage>
      </a>
    )}
    {twitter && (
      <a href={twitter} target="_blank" rel="noopener noreferrer">
        <ShareImage list={list}>
          <Icons.Twitter />
        </ShareImage>
      </a>
    )}
    {whatsapp && (
      <a href={whatsapp} target="_blank" rel="noopener noreferrer">
        <ShareImage list={list}>
          <Icons.Whatsapp />
        </ShareImage>
      </a>
    )}
    {email && (
      <a href={email}>
        <ShareImage list={list}>
          <Icons.Email />
        </ShareImage>
      </a>
    )}
  </ShareContainer>
);
