import React, { Component } from 'react';
import styled from 'styled-components';

const CrossLines = styled.div`
  background-color: #000;
  background-color: #000;
  height: 8px;
  width: 50%;
  left: 25%;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translateY(-50%) rotate(45deg);
  position: absolute;
  @media screen and (max-width: 900px) {
    height: 4px;
  }
  &:before {
    height: 100%;
    width: 100%;
    background-color: #000;
    position: absolute;
    content: '';
  }
  &:before {
    transform-origin: 50% 50%;
    transform: rotate(90deg);
  }
`;

const Container = styled.div`
  height: 100px;
  color: #000;
  background-color: #ffed00;
  width: 100px;
  display: inline-block;
  float: right;
  position: relative;
  @media screen and (max-width: 900px) {
    height: 50px;
    width: 50px;
  }
  &:hover {
    cursor: pointer;
    ${CrossLines} {
      animation: burger-glitch;
      animation-duration: 0.2s;
      @keyframes burger-glitch {
        0% {
          transform: translateX(10%) translateY(-50%);
        }
        5% {
          transform: translateX(17%) translateY(-60%);
        }
        15% {
          transform: translateX(-2%) translateY(10%);
        }
        20% {
          transform: translateX(-12%) translateY(20%);
        }
        25% {
          transform: translateY(-50%);
        }
      }
      &:before {
        animation: burger-glitch-before;
        animation-duration: 0.2s;
        @keyframes burger-glitch-before {
          0% {
            background-color: cyan;
          }
          5% {
            background-color: #ffed00;
          }
          15% {
            background-color: magenta;
          }
          20% {
            background-color: pink;
          }
          25% {
            background-color: #000;
          }
        }
      }
    }
  }
`;

export default class Burger extends Component {
  render() {
    return (
      <Container {...this.props}>
        <CrossLines />
      </Container>
    );
  }
}
