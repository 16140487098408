import styled from 'styled-components';

export default styled.div`
  height: 100px;
  width: 100vw;
  position: relative;
  @media screen and (max-width: 900px) {
    height: 50px;
  }
`;
