import React, { Component } from 'react';
import styled from 'styled-components';
// import ticketButton from '../img/ticket-button.svg';

const Container = styled.a`
  height: 100px;
  color: #fff;
  position: relative;
  display: inline-block;
  float: right;
  margin-right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  padding: 0;
  line-height: 100px;
  text-decoration: none;
  font-size: 32px;
  cursor: pointer;
  &:hover {
    color: #ffed00;
  }
  @media screen and (max-width: 900px) {
    height: 50px;
    line-height: 50px;
  }
  > img {
    width: 100%;
    height: 100%;
  }
`;

export default class Navigation extends Component {
  
  render() {
    let isIE = null;
    if (typeof document !== 'undefined') {
      isIE = /*@cc_on!@*/false || !!document.documentMode;
    }
    return <Container style={isIE? {backgroundColor: "black"} : null} href={isIE ? 'https://tickets.voordemensen.nl/angstfabriek/cal' : '/tickets'}>TICKETS</Container>;
  }
}
