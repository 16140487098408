import React from 'react';
import styled from 'styled-components';
import DataCard from '../../../components/DataCard';
import { Content as _Content } from '@wingscms/react';

const Content = styled(_Content)`
  aside {
    font-family: 'Edmondsans' !important;
  }
`;

export default ({ cardProps, ...props }) => {
  return (
    <Content
      {...props}
      cards={[DataCard]}
      cardProps={{
        ...cardProps
      }}
    />
  );
};
